import { useCallback, useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import { Link, useParams } from "react-router-dom";
import { BounceLoader } from "react-spinners";
import { Maneye_img, No_ProfImg } from "../../assets/images";
import { CurrencyFormating } from "../../helpers/currencyHelper";
import axios from "../../axios/axios";
import message from "../../messages";
import moment from "moment";

export default function CreatorNftDetails() {
  const { userId } = useParams();
  const [loader, setLoader] = useState(false);
  const [nftData, setNftData] = useState([]);
  const [userDetails, setUserDetails] = useState({});

  const getNftData = useCallback(() => {
    setLoader(true);

    axios.get(`/creator-nft-details/${userId}`).then((res) => {
      setLoader(false);
      if (res.data.success) {
        const { reports, userDetails } = res.data;
        setNftData(reports);
        setUserDetails(userDetails);
      } else {
        message.warning("Oops! Server error");
      }
    });
  }, [userId]);

  useEffect(() => {
    getNftData();
  }, [getNftData]);

  return (
    <div>
      <div className="dash_partts">
        <div className="dash_part_heading">
          <div className="dash_part_heading_left">
            <h2>NFT's Collectables</h2>
          </div>
        </div>

        <div className="reporters_profile">
          <div className="report_profile_image">
            <img
              src={userDetails?.creator?.thumbnailUrl || No_ProfImg}
              alt={userDetails?.creator?.userName}
              loading="lazy"
              width={154}
              height={154}
            />
          </div>
          <div className="reporter_info">
            <h1 className="reporter_name">{userDetails?.creator?.name}</h1>
            <div className="reporter_user_name">
              @{userDetails?.creator?.userName}
            </div>
            <div className="created_date">
              Created :{" "}
              {moment(userDetails?.creator?.createdAt).format("YYYY-MM-DD")}
            </div>
            <div className="info_list">
              <dl className="info_list_item">
                <dt>Total NFTs</dt>
                <dd>{userDetails?.NFT_Count || 0}</dd>
              </dl>
              <dl className="info_list_item">
                <dt>Total Edition</dt>
                <dd>{userDetails?.editionTotal || 0}</dd>
              </dl>
              {userDetails?.nftTypes?.map(({ name, count }, key) => {
                return (
                  <dl className="info_list_item" key={key}>
                    <dt>{name}</dt>
                    <dd>{count}</dd>
                  </dl>
                );
              })}
            </div>
          </div>
        </div>

        <div className="nft_collect_body">
          <LoadingOverlay
            active={loader}
            spinner={<BounceLoader color="white" />}
          >
            <div className="nft_collect_inner_body">
              {nftData.map(
                (
                  { NFT_identity, nftDetails, nftHolder, nftPrice, nftTypes },
                  index
                ) => {
                  return (
                    <div key={index} className="nft_collct_inner_body_part">
                      <div className="nft_collect_inner_partts">
                        <div className="nft_collect_top_img">
                          <img
                            src={nftDetails?.fileUrl}
                            alt="No Img"
                            loading="lazy"
                            decoding="async"
                          />
                        </div>
                        <div className="nft_collect_bottom_details">
                          <div className="nft_collect_details_left">
                            <h2>
                              {nftDetails?.title ||
                                userDetails?.creator?.nickName}
                            </h2>
                            <h5>
                              <small>
                                <i>Price</i>
                              </small>
                              : {CurrencyFormating(nftPrice.amount || 0)}
                            </h5>
                            <h5>
                              <small>
                                <i>Current Holder</i>
                              </small>
                              : {nftHolder?.name}
                            </h5>
                            <h5>
                              <small>
                                <i>Total Edition</i>
                              </small>
                              : {nftDetails?.editionTotal}
                            </h5>
                          </div>
                          <div className="nft_collect_details_right">
                            <div className="view_but">
                              <Link
                                to={`/nftdetails/${nftDetails?._id}/${NFT_identity}`}
                              >
                                <img src={Maneye_img} alt="No Img" />
                                View
                              </Link>
                            </div>
                            <div className="common_img">
                              <img src={nftTypes?.imageUrl} alt="No Img" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                }
              )}
            </div>
          </LoadingOverlay>
        </div>
      </div>
    </div>
  );
}
