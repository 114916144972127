import React from "react";

function Item(props) {
  return (
    <div className={`item_info ${props.className}`} onClick={props?.onClick}>
      <div className="info_value">{props.value}</div>
      <span className="info_title">{props.title}</span>
      {props.desc && <p className="info_desc">{props.desc}</p>}
    </div>
  );
}

export default Item;
