import React, { useEffect, useState } from "react";
import { Dropdown, Form, Button } from "react-bootstrap";
import "./management.css";
import axios from "../../axios/axios";
import { message } from "antd";
import LoadingOverlay from "react-loading-overlay";
import { PulseLoader } from "react-spinners";
import ImageCroper from "./ImageCroper";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "ckeditor5/ckeditor5.css";

const Management = () => {
  const [managementData, setManagementData] = useState({
    heading: "",
    content: "",
    manageKey: "",
  });
  const [imageFile, setImageFile] = useState();

  const [pages, setPages] = useState([]);
  const [ckEditorElm, setCkEditorElm] = useState(null);
  const [editorContent, setEditorcontent] = useState("");
  const [getLoading, setGetLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  // ## API Callings
  const getPages = async () => {
    setGetLoading(true);
    await axios
      .get(`/get-pages`)
      .then((response) => {
        const data = response.data.data;
        setPages(data);
        setGetLoading(false);
        // console.log("...........privacy-policy", data);
      })
      .catch((error) => {
        console.log(error);
        setGetLoading(false);
      });
  };
  const updatePage = async (key) => {
    setLoading(true);
    let content = editorContent;
    let payload;
    // ## For transparent UI requerment on app this is required
    if (key == "about_us") {
      content = content.replace("background-color:black;", "");
      payload = new FormData();
      payload.append("content", content);
      payload.append("heading", managementData.heading);
      payload.append("imageFile", imageFile);
    } else {
      payload = {
        content: content,
        heading: managementData.heading,
      };
    }
    await axios
      .post(`/update-pages/${key}`, payload)
      .then((response) => {
        if (response.data.success === true) {
          message.success("Updated successfully.");
          getPages();
          setLoading(false);
        } else {
          console.log("error");
          message.error("Update failed.");
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };
  useEffect(() => {
    getPages();
  }, []);

  useEffect(() => {}, [imageFile]);

  const setManagement = (manage) => {
    let data = pages.find((a) => a.key == manage);
    // ## For transparent UI requerment on app this is required
    // ## other wise text not vissible
    if (manage == "about_us") {
      data.content = data.content.replace(
        "body {",
        "body { background-color:black;"
      );
    }

    setManagementData({
      heading: data.heading,
      content: data.content,
      manageKey: data.key,
    });
    // setUpdateData({ ...updateData, key: data.key })
    ckEditorElm.setData(data.content);
  };

  const initEditorElement = ({ editor }) => {
    setCkEditorElm(editor);
  };

  return (
    <>
      <div className="dash_partts manage_dash">
        <div className="manage_drop">
          <LoadingOverlay
            className="download_loading"
            active={getLoading}
            spinner={
              <PulseLoader color="white" size={10} style={{ opacity: "40%" }} />
            }
          >
            <Dropdown className="drop_partts121">
              <Dropdown.Toggle variant="" id="dropdown-basic">
                Management
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {pages.map((p, index) => {
                  return (
                    <Dropdown.Item
                      key={index}
                      onClick={() => setManagement(p.key)}
                    >
                      {p.heading}
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          </LoadingOverlay>
        </div>

        <div>
          <h2 className="text-white">{managementData?.heading}</h2>
        </div>

        <React.Fragment>
          <div className="management_field">
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Heading</Form.Label>
              <Form.Control
                type="text"
                className="text_box"
                name="heading"
                value={managementData?.heading}
                // onChange={handleChange}
                readOnly={true}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Content</Form.Label>
              <CKEditor
                editor={ClassicEditor}
                config={{
                  allowedContent: true,
                }}
                data={""}
                onInstanceReady={initEditorElement}
                onChange={(_, editor) => {
                  setEditorcontent(editor.getData());
                }}
              />
            </Form.Group>
            {managementData.manageKey == "about_us" && (
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <div className="flex justify-center">
                  <div className="crop-container-outer mb-4 d-flex justify-content-center">
                    <ImageCroper setImageFile={setImageFile} />
                  </div>
                </div>
              </Form.Group>
            )}
          </div>
        </React.Fragment>
        <div className="management_submit">
          <LoadingOverlay
            className="download_loading fix_loader"
            active={loading}
            spinner={
              <PulseLoader color="white" size={10} style={{ opacity: "40%" }} />
            }
          >
            <Button
              type="submit"
              className="upload_submit"
              onClick={() => {
                updatePage(managementData.manageKey);
              }}
            >
              Update
            </Button>
          </LoadingOverlay>
        </div>
      </div>
    </>
  );
};

export default Management;
