import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "../../axios/axios";
import message from "../../messages";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { No_ProfImg } from "../../assets/images";
import { CurrencyFormating } from "../../helpers/currencyHelper";

export default function TopPerformerReport() {
  const token = useSelector((state) => state.setToken);
  const [loading, setLoading] = useState(true);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [allReports, setAllReports] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [selectedStartDate, setSelectedStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedEndDate, setSelectedEndDate] = useState("");
  const [sortBy, setSortBy] = useState("totalNFTs");
  const [orderBy, setOrderBy] = useState("desc");

  const getTopPerformerReports = useCallback(() => {
    if (endDate && !startDate) {
      return;
    }

    setLoading(true);
    axios
      .get(
        `/top-performer-reports?limit=${limit}&page=${page}&startDate=${startDate}&endDate=${endDate}&sortBy=${sortBy}&orderBy=${orderBy}`,
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((res) => {
        setLoading(false);
        if (res.data.success) {
          const { reports, pagination } = res.data;
          setTotalRows(pagination.total);
          setAllReports(reports);
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.error("err", err);
        message.error("Something went wrong");
      });
  }, [limit, page, token, startDate, endDate, sortBy, orderBy]);

  useEffect(() => {
    getTopPerformerReports();
  }, [getTopPerformerReports]);

  const COLUMNS = [
    {
      name: "Name",
      selector: ({ thumbnailUrl, name, userName, _id }) => (
        <div className="management_list_name">
          <img
            className="reporter_division_image"
            src={thumbnailUrl || No_ProfImg}
            alt={userName}
            loading="lazy"
          />

          <Link to={`/userdetails/${_id}`} className="reporter_divsor_name">
            {name}
          </Link>
        </div>
      ),
      width: "350px",
    },
    {
      name: "Username",
      selector: ({ userName, _id }) => (
        <Link to={`/userdetails/${_id}`} className="reporter_username">
          @{userName}
        </Link>
      ),
      width: "200px",
    },
    {
      name: "Total NFTs",
      selector: (row) => row?.totalNFTs,
      width: "200px",
      sortable: true,
      sortField: "totalNFTs",
    },
    {
      name: "Total NFT Price",
      selector: (row) => CurrencyFormating(row?.totalNFTPrice || 0),
      width: "200px",
      sortable: true,
      sortField: "totalNFTPrice",
    },
    {
      name: "Created",
      selector: (row) => moment(row?.createdAt).format("YYYY-MM-DD"),
      width: "200px",
    },
    {
      name: "Action",
      selector: (row) => (
        <>
          <Link
            to={`/reports/top-performer/${row?.user_id}`}
            className="btn btn-info"
          >
            View Details
          </Link>
        </>
      ),
      width: "200px",
    },
  ];

  const handlePerRowsChange = (newPerPage) => {
    setLoading(true);
    setLimit(newPerPage);
    setPage(1);
  };

  const changePage = (page) => {
    setLoading(true);
    setPage(page);
  };

  return (
    <div className="home_new">
      <div className="dash_partts_early">
        <div className="dash_part_heading">
          <div className="dash_part_heading_left">
            <h2>Top Performer Report</h2>
          </div>
        </div>
      </div>

      <div className="dash_partts_inner">
        <Card className="filter_card mt-3" style={{ overflow: "visible" }}>
          <Card.Body>
            <Container>
              <Row>
                <Col md={12}>
                  <Row>
                    <Col md={4}>
                      <label className="form-label" htmlFor="startDate">
                        Start Date
                      </label>

                      <DatePicker
                        id="startDate"
                        className="form-control"
                        type="date"
                        selected={selectedStartDate || ""}
                        onChange={(e) => {
                          setStartDate(moment(e).format("YYYY-MM-DD"));
                          setSelectedStartDate(e);
                        }}
                        // minDate={selectedStartDate || ""}
                        maxDate={selectedEndDate || new Date()}
                        placeholderText="YYYY-MM-DD"
                        dateFormat={"yyyy-MM-dd"}
                      />
                    </Col>
                    <Col md={4}>
                      <label className="form-label" htmlFor="endDate">
                        End Date
                      </label>

                      <DatePicker
                        id="endDate"
                        className="form-control"
                        type="date"
                        selected={selectedEndDate || ""}
                        onChange={(e) => {
                          setEndDate(moment(e).format("YYYY-MM-DD"));
                          setSelectedEndDate(e);
                        }}
                        minDate={selectedStartDate || ""}
                        maxDate={new Date()}
                        placeholderText="YYYY-MM-DD"
                        dateFormat={"yyyy-MM-dd"}
                      />
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <label className="form-label">&nbsp;</label>
                        <button
                          className="form-control filter_submit btn"
                          onClick={() => {
                            setEndDate("");
                            setSelectedEndDate("");
                            setStartDate("");
                            setSelectedStartDate("");
                          }}
                        >
                          Clear
                        </button>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </Card.Body>
        </Card>

        <div className="manage_table">
          {/* <div className="export_csv text-end p-4">
            <Button>Export CSV</Button>
          </div> */}
          <DataTable
            className="hypTable1"
            columns={COLUMNS}
            data={allReports}
            responsive
            customStyles={customStyles}
            sortServer={true}
            defaultSortField="totalNFTs"
            defaultSortAsc={false}
            noDataComponent={<EmptyDataLoader />}
            pagination
            paginationServer
            paginationTotalRows={Number(totalRows)}
            paginationPerPage={Number(limit)}
            paginationRowsPerPageOptions={[10, 20, 40]}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={changePage}
            onSort={(data, sortDirection) => {
              setOrderBy(sortDirection);
              setSortBy(data.sortField);
            }}
            progressPending={loading}
            progressComponent={<CustomLoader />}
          />
        </div>
      </div>
    </div>
  );
}

const customStyles = {
  rows: {
    style: {
      //minHeight: '72px',
      backgroundColor: "transparent",
      color: "#fff",
      paddingLeft: "10px", // override the row height
    },
  },
  headCells: {
    style: {
      paddingLeft: "10px", // override the cell padding for head cells
      paddingRight: "2px",
      backgroundColor: "transparent",
      fontSize: "20px",
      fontWeight: "600",
      color: "#fff",
    },
  },
  cells: {
    style: {
      paddingLeft: "4px", // override the cell padding for data cells
      paddingRight: "2px",
      paddingTop: "5px",
      paddingBottom: "5px",
      backgroundColor: "transparent",
      fontSize: "18px",
      color: "#fff",
    },
  },
};

const CustomLoader = () => (
  <div
    style={{
      padding: "15px",
      background: "#495A71",
      color: "#fff",
      width: "100%",
      fontSize: "40px",
      textAlign: "center",
      minHeight: "350px",
      lineHeight: "400px",
    }}
  >
    <div>Loading...</div>
  </div>
);
const EmptyDataLoader = () => (
  <div
    style={{
      padding: "15px",
      background: "#495A71",
      color: "#fff",
      width: "100%",
      fontSize: "40px",
      textAlign: "center",
      minHeight: "350px",
      lineHeight: "400px",
    }}
  >
    <div>No data found...</div>
  </div>
);
