import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "../../axios/axios";
import message from "../../messages";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
} from "react-bootstrap";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { No_ProfImg } from "../../assets/images";
import {
  CurrencyFormating,
  currencyFormatingInString,
} from "../../helpers/currencyHelper";

export default function UserProfitLossReport() {
  const token = useSelector((state) => state.setToken);
  const [loading, setLoading] = useState(true);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [allReports, setAllReports] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [selectedStartDate, setSelectedStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedEndDate, setSelectedEndDate] = useState("");
  const [sortBy, setSortBy] = useState("totalProfit");
  const [orderBy, setOrderBy] = useState("desc");
  const [filterText, setFilterText] = useState("");
  const [filter, setFilter] = useState("");

  const getTopPerformerReports = useCallback(() => {
    if (endDate && !startDate) {
      return;
    }

    setLoading(true);
    axios
      .get(
        `/user-profit-loss-reports?limit=${limit}&page=${page}&startDate=${startDate}&endDate=${endDate}&sortBy=${sortBy}&orderBy=${orderBy}&filter=${filter}`,
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((res) => {
        setLoading(false);
        if (res.data.success) {
          const { reports, pagination } = res.data;
          setTotalRows(pagination.total);
          setAllReports(reports);
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.error("err", err);
        message.error("Something went wrong");
      });
  }, [limit, page, token, startDate, endDate, sortBy, orderBy, filter]);

  useEffect(() => {
    getTopPerformerReports();
  }, [getTopPerformerReports]);

  const COLUMNS = [
    {
      name: "Name",
      selector: ({ userDetails }) => (
        <div className="management_list_name">
          <img
            className="reporter_division_image"
            src={userDetails?.thumbnailUrl || No_ProfImg}
            alt={userDetails?.userName}
            loading="lazy"
          />

          <Link
            to={`/userdetails/${userDetails?._id}`}
            className="reporter_divsor_name"
          >
            {userDetails?.name}
          </Link>
        </div>
      ),
      width: "350px",
    },
    {
      name: "Username",
      selector: ({ userDetails }) => (
        <Link
          to={`/userdetails/${userDetails?._id}`}
          className="reporter_username"
        >
          @{userDetails?.userName}
        </Link>
      ),
      width: "200px",
    },
    {
      name: "Total Profit",
      selector: (row) => (
        <div className="profit">{CurrencyFormating(row?.totalProfit || 0)}</div>
      ),
      width: "200px",
      sortable: true,
      sortField: "totalProfit",
    },
    {
      name: "Total Loss",
      selector: (row) => (
        <div className="loss">{CurrencyFormating(row?.totalLoss || 0)}</div>
      ),
      width: "200px",
      sortable: true,
      sortField: "totalLoss",
    },
    {
      name: "Current Value",
      selector: (row) => (
        <div
          className={`${
            row?.totalProfit >= row?.totalLoss ? "profit" : "loss"
          } `}
        >
          {CurrencyFormating(row?.totalProfit - row?.totalLoss || 0)}
        </div>
      ),
      width: "200px",
    },

    {
      name: "Action",
      selector: (row) => (
        <>
          <Link
            to={`/reports/user-profit-loss/${row?._id}`}
            className="btn btn-info"
          >
            View Details
          </Link>
        </>
      ),
      width: "200px",
    },
  ];

  const handlePerRowsChange = (newPerPage) => {
    setLoading(true);
    setLimit(newPerPage);
    setPage(1);
  };

  const changePage = (page) => {
    setLoading(true);
    setPage(page);
  };

  const downlodProfitLossData = () => {
    setLoading(true);
    axios
      .get(
        `/download-profit-loss-reports?startDate=${startDate}&endDate=${endDate}&sortBy=${sortBy}&orderBy=${orderBy}&filter=${filter}`,
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((res) => {
        setLoading(false);
        if (res.data.success) {
          const { reports } = res.data;

          const data = reports.map(
            ({ totalLoss, totalProfit, userDetails }) => {
              return {
                name: userDetails?.name,
                Username: "@" + userDetails?.userName,
                email: userDetails?.email,
                "Total Profit": currencyFormatingInString(totalProfit || 0),
                "Total Loss": currencyFormatingInString(totalLoss || 0),
                "Current Value": currencyFormatingInString(
                  totalProfit - totalLoss || 0
                ),
                "Profile Photo": userDetails?.thumbnailUrl,
              };
            }
          );

          if (data.length > 0) {
            const headers = Object.keys(data[0]).join(",");
            const rows = data
              .map((item) => Object.values(item).join(","))
              .join("\n");

            const csvContent = `${headers}\n${rows}`;

            const blob = new Blob([csvContent], { type: "text/csv" });
            const url = URL.createObjectURL(blob);

            const link = document.createElement("a");
            link.href = url;
            link.download = "user_profit_loss_report.csv";
            link.click();

            URL.revokeObjectURL(url);
          }
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.error("err", err);
        message.error("Something went wrong");
      });
  };

  return (
    <div className="home_new">
      <div className="dash_partts_early">
        <div className="dash_part_heading">
          <div className="dash_part_heading_left">
            <h2>User Profit Loss Report</h2>
          </div>
        </div>
      </div>

      <div className="dash_partts_inner">
        <Card className="filter_card mt-3" style={{ overflow: "visible" }}>
          <Card.Body>
            <Container>
              <Row>
                <Col md={6}>
                  <label className="form-label" htmlFor="startDate">
                    Search
                  </label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      placeholder="Search here ..."
                      aria-label="Search here ..."
                      value={filterText}
                      onChange={(e) => {
                        setFilterText(e.target.value);
                      }}
                    />
                    <Button
                      variant="outline-secondary"
                      className="mb-0"
                      onClick={() => {
                        setFilter(filterText.trim());
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M15.796 15.811 21 21m-3-10.5a7.5 7.5 0 1 1-15 0 7.5 7.5 0 0 1 15 0Z"
                          stroke="currentcolor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </Button>
                  </InputGroup>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <label className="form-label" htmlFor="startDate">
                    Start Date
                  </label>

                  <DatePicker
                    id="startDate"
                    className="form-control"
                    type="date"
                    selected={selectedStartDate || ""}
                    onChange={(e) => {
                      setStartDate(moment(e).format("YYYY-MM-DD"));
                      setSelectedStartDate(e);
                    }}
                    // minDate={selectedStartDate || ""}
                    maxDate={selectedEndDate || new Date()}
                    placeholderText="YYYY-MM-DD"
                    dateFormat={"yyyy-MM-dd"}
                  />
                </Col>
                <Col md={4}>
                  <label className="form-label" htmlFor="endDate">
                    End Date
                  </label>

                  <DatePicker
                    id="endDate"
                    className="form-control"
                    type="date"
                    selected={selectedEndDate || ""}
                    onChange={(e) => {
                      setEndDate(moment(e).format("YYYY-MM-DD"));
                      setSelectedEndDate(e);
                    }}
                    minDate={selectedStartDate || ""}
                    maxDate={new Date()}
                    placeholderText="YYYY-MM-DD"
                    dateFormat={"yyyy-MM-dd"}
                  />
                </Col>
                <Col md="2">
                  <div className="mb-3">
                    <label className="form-label">&nbsp;</label>
                    <button
                      className="form-control filter_submit btn"
                      onClick={() => {
                        setEndDate("");
                        setSelectedEndDate("");
                        setStartDate("");
                        setSelectedStartDate("");
                        setFilter("");
                        setFilterText("");
                      }}
                    >
                      Clear
                    </button>
                  </div>
                </Col>
              </Row>
            </Container>
          </Card.Body>
        </Card>

        <div className="export_csv text-end float-right mt-3">
          <Button style={{backgroundColor:"#0d989c"}} onClick={downlodProfitLossData}>Export CSV</Button>
        </div>
        <div className="manage_table ">
       
          <DataTable
            className="hypTable1"
            columns={COLUMNS}
            data={allReports}
            responsive
            customStyles={customStyles}
            sortServer={true}
            defaultSortField="totalProfit"
            defaultSortAsc={false}
            noDataComponent={<EmptyDataLoader />}
            pagination
            paginationServer
            paginationTotalRows={Number(totalRows)}
            paginationPerPage={Number(limit)}
            paginationRowsPerPageOptions={[10, 20, 40]}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={changePage}
            onSort={(data, sortDirection) => {
              setOrderBy(sortDirection);
              setSortBy(data.sortField);
            }}
            progressPending={loading}
            progressComponent={<CustomLoader />}
          />
        </div>
      </div>
    </div>
  );
}

const customStyles = {
  rows: {
    style: {
      //minHeight: '72px',
      backgroundColor: "transparent",
      color: "#fff",
      paddingLeft: "10px", // override the row height
    },
  },
  headCells: {
    style: {
      paddingLeft: "10px", // override the cell padding for head cells
      paddingRight: "2px",
      backgroundColor: "transparent",
      fontSize: "20px",
      fontWeight: "600",
      color: "#fff",
    },
  },
  cells: {
    style: {
      paddingLeft: "4px", // override the cell padding for data cells
      paddingRight: "2px",
      paddingTop: "5px",
      paddingBottom: "5px",
      backgroundColor: "transparent",
      fontSize: "18px",
      color: "#fff",
    },
  },
};

const CustomLoader = () => (
  <div
    style={{
      padding: "15px",
      background: "#495A71",
      color: "#fff",
      width: "100%",
      fontSize: "40px",
      textAlign: "center",
      minHeight: "350px",
      lineHeight: "400px",
    }}
  >
    <div>Loading...</div>
  </div>
);
const EmptyDataLoader = () => (
  <div
    style={{
      padding: "15px",
      background: "#495A71",
      color: "#fff",
      width: "100%",
      fontSize: "40px",
      textAlign: "center",
      minHeight: "350px",
      lineHeight: "400px",
    }}
  >
    <div>No data found...</div>
  </div>
);
