import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";
import { PulseLoader } from "react-spinners";
import axios from "../../axios/axios";
import { message } from "antd";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "ckeditor5/ckeditor5.css";

const AddFaq = () => {
  const history = useHistory();
  const [faqData, setFaqData] = useState({
    question: "",
    answer: "",
    // manageKey: "",
  });

  const [editorContent, setEditorcontent] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});

  const validation = () => {
    let isValid = [];

    // Question error check
    if (faqData.question.trim() === "") {
      setError((prevState) => ({
        ...prevState,
        questionError: "Question Is Required",
      }));
      isValid.push(false);
    } else {
      setError((prevState) => ({ ...prevState, questionError: "" }));
      isValid.push(true);
    }

    // Answer error check
    if (editorContent === "") {
      setError((prevState) => ({
        ...prevState,
        answerError: "Answer Is Required",
      }));
      isValid.push(false);
    } else {
      setError((prevState) => ({ ...prevState, answerError: "" }));
      isValid.push(true);
    }

    if (isValid.includes(false)) {
      return false;
    } else {
      return true;
    }
  };

  const handleChange = (e) => {
    setFaqData({
      ...faqData,
      [e.target.name]: e.target.value,
    });
  };

  // add new faq api call
  const addFaq = async (e) => {
    e.preventDefault();
    let validate = validation();

    if (validate) {
      faqData.answer = editorContent;
      setLoading(true);
      await axios
        .post("/add-faq", faqData)
        .then((res) => {
          if (res.data.success === false) {
            // console.log("response...", res);
            message.success("Add New Faq successfully.");
            history.push("/faq");
            setLoading(false);
          } else {
            console.log("error");
            message.error("Add New Faq Failed.");
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } else {
      message.error("Please enter mandatory fields");
    }
  };

  return (
    <React.Fragment>
      <div className="dash_partts manage_dash">
        <React.Fragment>
          <div className="management_field">
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Question</Form.Label>
              <Form.Control
                type="text"
                className="text_box"
                name="question"
                // value={faqData?.question}
                onChange={handleChange}
              />
              <p className="errorMessage">{error.questionError}</p>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Answer</Form.Label>
              <CKEditor
                config={{
                  allowedContent: true,
                }}
                data={""}
                onChange={(_, editor) => {
                  setEditorcontent(editor.getData());
                }}
                editor={ClassicEditor}
              />
              <p className="errorMessage">{error.answerError}</p>
            </Form.Group>
          </div>
        </React.Fragment>
        <div className="management_submit">
          <LoadingOverlay
            className="download_loading fix_loader"
            active={loading}
            spinner={
              <PulseLoader color="white" size={10} style={{ opacity: "40%" }} />
            }
          >
            <Button type="submit" className="upload_submit" onClick={addFaq}>
              Submit
            </Button>
          </LoadingOverlay>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AddFaq;
