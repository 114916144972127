import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "../../axios/axios";
import message from "../../messages";
import DataTable from "react-data-table-component";
import { useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { CurrencyFormating } from "../../helpers/currencyHelper";
import moment from "moment";
import { No_ProfImg } from "../../assets/images";

export default function UserFundDetails() {
  const token = useSelector((state) => state.setToken);
  const { userId } = useParams();
  const [loading, setLoading] = useState(true);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [allReports, setAllReports] = useState([]);
  const [userDetails, setUserDetails] = useState({});

  const getTopPerformerReports = useCallback(() => {
    setLoading(true);
    axios
      .get(`/user-fund-reports/${userId}?limit=${limit}&page=${page}`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        setLoading(false);
        if (res.data.success) {
          const { reports, pagination, userDetails } = res.data;
          setTotalRows(pagination.total);
          setAllReports(reports);
          setUserDetails(userDetails);
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.error("err", err);
        message.error("Something went wrong");
      });
  }, [limit, page, token, userId]);

  useEffect(() => {
    getTopPerformerReports();
  }, [getTopPerformerReports]);

  const COLUMNS = [
    {
      name: "Type",
      selector: (row) => row?.type,
      width: "100px",
    },

    {
      name: "Balance",
      selector: (row) => CurrencyFormating(row?.balance || 0),
      width: "300px",
    },
    {
      name: "Purpose",
      selector: (row) => row.creditFor || row.debitFor || row.description,
      width: "300px",
    },
    {
      name: "Description",
      selector: (row) => row.description,
      width: "350px",
    },
    {
      name: "Status",
      selector: (row) => row?.status,
      width: "100px",
    },
  ];

  const handlePerRowsChange = (newPerPage) => {
    setLoading(true);
    setLimit(newPerPage);
    setPage(1);
  };

  const changePage = (page) => {
    setLoading(true);
    setPage(page);
  };

  return (
    <div className="dash_partts">
      <div className="dash_part_heading">
        <div className="dash_part_heading_left">
          <h2>User Fund Report</h2>
        </div>
      </div>

      <div className="reporters_profile">
        <div className="report_profile_image">
          <img
            src={userDetails?.users?.thumbnailUrl || No_ProfImg}
            alt={userDetails?.users?.userName}
            loading="lazy"
            width={154}
            height={154}
          />
        </div>
        <div className="reporter_info">
          <h1 className="reporter_name">{userDetails?.users?.name}</h1>
          <div className="reporter_user_name">
            @{userDetails?.users?.userName}
          </div>
          <div className="created_date">
            Created :{" "}
            {moment(userDetails?.users?.createdAt).format("YYYY-MM-DD")}
          </div>
          <div className="info_list">
            <dl className="info_list_item">
              <dt>Total Credit</dt>
              <dd className="credit_color">
                {CurrencyFormating(userDetails?.total_credit || 0)}
              </dd>
            </dl>
            <dl className="info_list_item">
              <dt>Total Debit</dt>
              <dd className="debit_color">
                {CurrencyFormating(userDetails?.total_debit || 0)}
              </dd>
            </dl>
            <dl className="info_list_item">
              <dt>Total Withdraw</dt>
              <dd className="withdraw_color">
                {CurrencyFormating(userDetails?.total_withdraw || 0)}
              </dd>
            </dl>
          </div>
        </div>
      </div>

      <div className="manage_table table-responsive">
        <DataTable
          className="hypTable1"
          columns={COLUMNS}
          data={allReports}
          responsive
          customStyles={customStyles}
          defaultSortField="totalNFTs"
          defaultSortAsc={false}
          noDataComponent={<EmptyDataLoader />}
          pagination
          paginationServer
          paginationTotalRows={Number(totalRows)}
          paginationPerPage={Number(limit)}
          paginationRowsPerPageOptions={[10, 20, 40]}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={changePage}
          progressPending={loading}
          progressComponent={<CustomLoader />}
        />
      </div>
    </div>
  );
}

const customStyles = {
  rows: {
    style: {
      //minHeight: '72px',
      backgroundColor: "transparent",
      color: "#fff",
      paddingLeft: "10px", // override the row height
    },
  },
  headCells: {
    style: {
      paddingLeft: "10px", // override the cell padding for head cells
      paddingRight: "2px",
      backgroundColor: "transparent",
      fontSize: "16px",
      color: "#fff",
    },
  },
  cells: {
    style: {
      paddingLeft: "4px", // override the cell padding for data cells
      paddingRight: "2px",
      paddingTop: "5px",
      paddingBottom: "5px",
      backgroundColor: "transparent",
      fontSize: "14px",
      color: "#fff",
    },
  },
};

const CustomLoader = () => (
  <div
    style={{
      padding: "15px",
      background: "#495A71",
      color: "#fff",
      width: "100%",
      fontSize: "40px",
      textAlign: "center",
      minHeight: "350px",
      lineHeight: "400px",
    }}
  >
    <div>Loading...</div>
  </div>
);
const EmptyDataLoader = () => (
  <div
    style={{
      padding: "15px",
      background: "#495A71",
      color: "#fff",
      width: "100%",
      fontSize: "40px",
      textAlign: "center",
      minHeight: "350px",
      lineHeight: "400px",
    }}
  >
    <div>No data found...</div>
  </div>
);
