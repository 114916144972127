import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "../../axios/axios";
import message from "../../messages";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { No_ProfImg } from "../../assets/images";
import { Button } from "react-bootstrap";

export default function CreatorReport() {
  const token = useSelector((state) => state.setToken);
  const [loading, setLoading] = useState(true);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [allReports, setAllReports] = useState([]);

  const getCreatorReports = useCallback(() => {
    setLoading(true);
    axios
      .get(`/creator-reports?limit=${limit}&page=${page}`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        setLoading(false);
        if (res.data.success) {
          const { reports, pagination } = res.data;
          setTotalRows(pagination.total);
          setAllReports(reports);
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.error("err", err);
        message.error("Something went wrong");
      });
  }, [limit, page, token]);

  useEffect(() => {
    getCreatorReports();
  }, [getCreatorReports]);

  const COLUMNS = [
    {
      name: "Author of NFTs",
      selector: ({ creator }) => (
        <div className="management_list_name">
          <img
            className="reporter_division_image"
            src={creator?.thumbnailUrl || No_ProfImg}
            alt={creator.userName}
            loading="lazy"
          />

          <Link
            to={`/userdetails/${creator?._id}`}
            className="reporter_divsor_name"
          >
            {creator?.name}
          </Link>
        </div>
      ),
      width: "300px",
    },
    {
      name: "Username",
      selector: ({ creator }) => (
        <Link to={`/userdetails/${creator?._id}`} className="reporter_username">
          @{creator.userName}
        </Link>
      ),
      width: "200px",
    },
    {
      name: "Total NFTs",
      selector: (row) => row?.NFT_Count,
      width: "150px",
    },
    {
      name: "Total Edition",
      selector: (row) => row.editionTotal,
      width: "150px",
    },
    {
      name: "NFT Types",
      selector: (row) => (
        <>
          {row.nftTypes.map((item, key) => {
            return (
              <div className="nft_type" key={key}>
                {item.name} : {item.count}
              </div>
            );
          })}
        </>
      ),
      width: "200px",
    },
    {
      name: "Action",
      selector: (row) => (
        <>
          <Link
            to={`/reports/creator/${row?.creator?._id}`}
            className="btn btn-info"
          >
            View More
          </Link>
        </>
      ),
      width: "200px",
    },
  ];

  const handlePerRowsChange = (newPerPage) => {
    setLoading(true);
    setLimit(newPerPage);
    setPage(1);
  };

  const changePage = (page) => {
    setLoading(true);
    setPage(page);
  };

  return (
    <div className="home_new">
      <div className="dash_partts_early">
        <div className="dash_part_heading">
          <div className="dash_part_heading_left">
            <h2>Creator Report</h2>
          </div>
        </div>
      </div>

      <div className="dash_partts_inner">
        <div className="manage_table">
          {/* <div className="export_csv text-end p-4">
            <Button>Export CSV</Button>
          </div> */}
          <DataTable
            className="hypTable1"
            columns={COLUMNS}
            data={allReports}
            responsive
            customStyles={customStyles}
            defaultSortField="id"
            defaultSortAsc={false}
            noDataComponent={<EmptyDataLoader />}
            pagination
            paginationServer
            // paginationTotalRows={count.totalNfts}
            paginationTotalRows={Number(totalRows)}
            paginationPerPage={Number(limit)}
            // paginationTotalRows={50}
            paginationRowsPerPageOptions={[10, 20, 40]}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={changePage}
            progressPending={loading}
            progressComponent={<CustomLoader />}
            // paginationDefaultPage={page}
          />
        </div>
      </div>
    </div>
  );
}

const customStyles = {
  rows: {
    style: {
      //minHeight: '72px',
      backgroundColor: "transparent",
      color: "#fff",
      paddingLeft: "10px", // override the row height
    },
  },
  headCells: {
    style: {
      paddingLeft: "10px", // override the cell padding for head cells
      paddingRight: "2px",
      backgroundColor: "transparent",
      fontSize: "20px",
      fontWeight: "600",
      color: "#fff",
    },
  },
  cells: {
    style: {
      paddingLeft: "4px", // override the cell padding for data cells
      paddingRight: "2px",
      paddingTop: "5px",
      paddingBottom: "5px",
      backgroundColor: "transparent",
      fontSize: "18px",
      color: "#fff",
    },
  },
};

const CustomLoader = () => (
  <div
    style={{
      padding: "15px",
      background: "#495A71",
      color: "#fff",
      width: "100%",
      fontSize: "40px",
      textAlign: "center",
      minHeight: "350px",
      lineHeight: "400px",
    }}
  >
    <div>Loading...</div>
  </div>
);
const EmptyDataLoader = () => (
  <div
    style={{
      padding: "15px",
      background: "#495A71",
      color: "#fff",
      width: "100%",
      fontSize: "40px",
      textAlign: "center",
      minHeight: "350px",
      lineHeight: "400px",
    }}
  >
    <div>No data found...</div>
  </div>
);
