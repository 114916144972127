import { useCallback, useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import LoadingOverlay from "react-loading-overlay";
import Item from "../HypeuTotals/cardinfo/item";
import { PulseLoader } from "react-spinners";
import { useSelector } from "react-redux";
import axios from "../../axios/axios";
import message from "../../messages";
import moment from "moment";
import { DatePicker } from "antd";
import "react-datepicker/dist/react-datepicker.css";

export default function DeviceWiseReport() {
  const token = useSelector((state) => state.setToken);
  const [loading, setLoading] = useState(true);
  const [allReports, setAllReports] = useState({});
  const [startDate, setStartDate] = useState("");
  const [selectedStartDate, setSelectedStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedEndDate, setSelectedEndDate] = useState("");

  const getPlatformReports = useCallback(() => {
    if (endDate && !startDate) {
      return;
    }

    setLoading(true);
    axios
      .get(`/platform-reports?startDate=${startDate}&endDate=${endDate}`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        setLoading(false);
        if (res.data.success) {
          const { reports } = res.data;
          setAllReports(reports);
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.error("err", err);
        message.error("Something went wrong");
      });
  }, [token, startDate, endDate]);

  useEffect(() => {
    getPlatformReports();
  }, [getPlatformReports]);

  return (
    <div className="home_new">
      <div className="dash_partts_early">
        <div className="dash_part_heading">
          <div className="dash_part_heading_left">
            <h2>Device Wise Report</h2>
          </div>
        </div>
      </div>

      <div className="dash_partts_inner">
        {/* <Card className="filter_card mt-3" style={{ overflow: "visible" }}>
          <Card.Body>
            <Container>
              <Row>
                <Col md={12}>
                  <Row>
                    <Col md={4}>
                      <label className="form-label" htmlFor="startDate">
                        Start Date
                      </label>

                      <DatePicker
                        id="startDate"
                        className="form-control"
                        type="date"
                        selected={selectedStartDate || ""}
                        onChange={(e) => {
                          setStartDate(moment(e).format("YYYY-MM-DD"));
                          setSelectedStartDate(e);
                        }}
                        // minDate={selectedStartDate || ""}
                        maxDate={selectedEndDate || new Date()}
                        placeholderText="YYYY-MM-DD"
                        dateFormat={"yyyy-MM-dd"}
                      />
                    </Col>
                    <Col md={4}>
                      <label className="form-label" htmlFor="endDate">
                        End Date
                      </label>

                      <DatePicker
                        id="endDate"
                        className="form-control"
                        type="date"
                        selected={selectedEndDate || ""}
                        onChange={(e) => {
                          setEndDate(moment(e).format("YYYY-MM-DD"));
                          setSelectedEndDate(e);
                        }}
                        minDate={selectedStartDate || ""}
                        maxDate={new Date()}
                        placeholderText="YYYY-MM-DD"
                        dateFormat={"yyyy-MM-dd"}
                      />
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <label className="form-label">&nbsp;</label>
                        <button
                          className="form-control filter_submit btn"
                          onClick={() => {
                            setEndDate("");
                            setSelectedEndDate("");
                            setStartDate("");
                            setSelectedStartDate("");
                          }}
                        >
                          Clear
                        </button>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </Card.Body>
        </Card> */}
        <LoadingOverlay
          active={loading}
          spinner={<PulseLoader color="white" size={10} />}
        >
          <Row>


            <Col lg={4} md={6} sm={12}>

              <div className="device_counte">
                <p>Installation Total</p>
                <div className="d-flex flex-wrap gapReport">
                  <Item
                    value={allReports?.platformReports?.totalAndroid || 0}
                    title="Android"
                    className="mt-2 reportingKeyMatrics"
                  />

                  <Item
                    value={allReports?.platformReports?.totaliOS || 0}
                    title="iOS"
                    className="mt-2 reportingKeyMatrics"
                  />
                </div>
              </div>
            </Col>
            <Col lg={4} md={6} sm={12}>
              <div className="device_counte">
                <p>Registration Total</p>
                <div className="gapReport d-flex flex-wrap">
                  <Item
                    value={allReports?.registerReports?.totalAndroid || 0}
                    title="Android"
                    className="mt-2 reportingKeyMatrics"
                  />

                  <Item
                    value={allReports?.registerReports?.totaliOS || 0}
                    title="iOS"
                    className="mt-2 reportingKeyMatrics"
                  />
                </div>
              </div>
            </Col>
            <Col lg={4} md={6} sm={12}>
              <div className="device_counte">
                <p>Login Total</p>
                <div className="gapReport d-flex flex-wrap">
                  <Item
                    value={allReports?.loginReports?.totalAndroid || 0}
                    title="Android"
                    className="mt-2 reportingKeyMatrics"
                  />

                  <Item
                    value={allReports?.loginReports?.totaliOS || 0}
                    title="iOS"
                    className="mt-2 reportingKeyMatrics"
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            
            <Col lg={6} md={12}>
              <div className="device_counte">
                <p>NFT Card Transactions Total</p>
                <div className="gapReport d-flex flex-wrap">
                  <Item
                    value={allReports?.nftTransactionReports?.totalAndroid || 0}
                    title="Android"
                    className="mt-2 reportingKeyMatrics"
                  />

                  <Item
                    value={allReports?.nftTransactionReports?.totaliOS || 0}
                    title="iOS"
                    className="mt-2 reportingKeyMatrics"
                  />
                </div>
              </div>
            </Col>
            <Col lg={6} md={12}>
              <div className="device_counte">
                <p>Wallet Recharge Total</p>
                <div className="gapReport d-flex flex-wrap">
                  <Item
                    value={allReports?.walletReports?.totalAndroid || 0}
                    title="Android"
                    className="mt-2 reportingKeyMatrics"
                  />

                  <Item
                    value={allReports?.walletReports?.totaliOS || 0}
                    title="iOS"
                    className="mt-2 reportingKeyMatrics"
                  />
                </div>
              </div>

            </Col>
          </Row>
          <Row>
          

          </Row>
        </LoadingOverlay>
      </div>
    </div>
  );
}
