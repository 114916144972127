import React, { useEffect } from "react";
import { Form } from "react-bootstrap";
import message from "../../messages";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "ckeditor5/ckeditor5.css";
import { useParams, useHistory } from "react-router-dom";
import axios from "../../axios/axios";
import TestMail from "./TestMail";

const EmailTemplate = () => {
  const { mailId } = useParams();
  const history = useHistory();

  const [emailDetails, setEmailDetails] = React.useState({
    name: "",
    subject: "",
    content: "",
    useKey: "",
  });

  const [editorContent, setEditorcontent] = React.useState("");

  const handleClick = (evt) => {
    setEmailDetails({
      ...emailDetails,
      [evt.target.name]: evt.target.value,
    });
  };

  console.log(
    "check mailId, message and subject.... : ",
    `${mailId}` + " " + emailDetails.subject + "  " + emailDetails.content
  );

  // get mail by id
  const getEmailDetail = async () => {
    await axios
      .get(`/mail-template-by-id/${mailId}`)
      .then((res) => {
        setEmailDetails(res.data.viewEmail);
        setEditorcontent(res.data.viewEmail.content);
      })
      .catch((err) => {
        console.log("getEmailDetail err", err);
      });
  };

  // update mail by id
  const updateEmailDetail = async () => {
    let dataurl = `mail-template/${mailId}`;

    // update value in the 'emailDetails -> content'
    emailDetails.content = editorContent;
    await axios
      .patch(dataurl, emailDetails)
      .then((resp) => {
        message.success(resp.data.message);
        history.push("/email-list");
      })
      .catch((error) => {
        console.log("mail-template-update error.....", error);
        message.error("edit mail template fail");
      });
  };

  useEffect(() => {
    getEmailDetail();
  }, []);

  return (
    <React.Fragment>
      <div className="user_inner_partts">
        <div className="sand_box_partts">
          <h2>Edit Email Template</h2>
          <div className="setting_right_partts_inner">
            <div className="notification_container">
              <div className="notification_container stripe">
                <div>
                  <Form.Label>Email Name</Form.Label>
                  <Form.Control
                    type="text"
                    className="notification_input_field"
                    name="name"
                    value={emailDetails?.name}
                    onChange={handleClick}
                  />
                </div>
              </div>

              <div className="notification_container stripe">
                <div>
                  <Form.Label>Email Subject</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Subject"
                    className="notification_input_field"
                    name="subject"
                    value={emailDetails?.subject}
                    onChange={handleClick}
                  />
                </div>
              </div>

              <div className="notification_container stripe">
                <Form.Label>Content</Form.Label>
                {editorContent != "" && (
                  <CKEditor
                    editor={ClassicEditor}
                    data={editorContent}
                    onChange={(_, editor) => {
                      setEditorcontent(editor.getData());
                    }}
                  />
                )}
              </div>

              <div className="notification_container stripe">
                <div>
                  <Form.Label>Use Key</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="useKey"
                    className="notification_input_field"
                    readOnly
                    name="useKey"
                    value={emailDetails?.useKey}
                    onChange={handleClick}
                  />
                </div>
              </div>

              <div className="email-button-container">
                <div>
                  <button
                    type="submit"
                    className="notification_update_button email-button"
                    onClick={updateEmailDetail}
                  >
                    Update
                  </button>
                </div>
                <div>
                  <TestMail sendMailId={mailId} sendMailDetail={emailDetails} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default EmailTemplate;
