import React, { useEffect, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "ckeditor5/ckeditor5.css";
import { Form, Button } from "react-bootstrap";
import LoadingOverlay from "react-loading-overlay";
import { PulseLoader } from "react-spinners";
import { useHistory, useParams } from "react-router-dom";
import axios from "../../axios/axios";
import { message } from "antd";

const EditFaq = () => {
  const { faqId } = useParams();
  const history = useHistory();
  const [faqData, setFaqData] = useState({
    question: "",
    answer: "",
  });

  const [ckEditorElm, setCkEditorElm] = useState("");
  // const [editorContent, setEditorcontent] = useState("");
  const [loading, setLoading] = useState(false);

  // get faq by id api call
  const getFaqById = async () => {
    await axios
      .get(`/get-faq/${faqId}`)
      .then((res) => {
        const data = res.data.faq;
        if (res.data.success === true) {
          setFaqData({
            ...faqData,
            question: data.question,
            answer: data.answer,
          });
        }
        setCkEditorElm(data.answer);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getFaqById();
  }, []);

  const handleChange = (e) => {
    setFaqData({
      ...faqData,
      [e.target.name]: e.target.value,
    });
  };

  // update faq api call
  const updateFaq = async () => {
    // faqData.answer = editorContent;
    faqData.answer = ckEditorElm;
    setLoading(true);
    await axios
      .put(`/update-faq/${faqId}`, faqData)
      .then((res) => {
        if (res.data.success === true) {
          message.success("Faq Updated successfully.");
          history.push("/faq");
          setLoading(false);
        } else {
          console.log("error");
          message.error("Update failed.");
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log("error", error);
        message.error("Faq Update failed.");
        setLoading(false);
      });
  };

  return (
    <React.Fragment>
      <div className="dash_partts manage_dash">
        <React.Fragment>
          <div className="management_field">
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Question</Form.Label>
              <Form.Control
                type="text"
                className="text_box"
                name="question"
                value={faqData?.question}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Answer</Form.Label>

              {ckEditorElm !== "" && (
                <CKEditor
                  editor={ClassicEditor}
                  data={ckEditorElm}
                  onChange={(_, editor) => {
                    setCkEditorElm(editor.getData());
                  }}
                />
              )}
            </Form.Group>
          </div>
        </React.Fragment>
        <div className="management_submit">
          <LoadingOverlay
            className="download_loading fix_loader"
            active={loading}
            spinner={
              <PulseLoader color="white" size={10} style={{ opacity: "40%" }} />
            }
          >
            <Button
              type="submit"
              className="upload_submit"
              onClick={() => updateFaq()}
            >
              Update
            </Button>
          </LoadingOverlay>
        </div>
      </div>
    </React.Fragment>
  );
};

export default EditFaq;
