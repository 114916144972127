import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "../../axios/axios";
import message from "../../messages";
import { Card, Col, Container, Row } from "react-bootstrap";
import moment from "moment";
import LoadingOverlay from "react-loading-overlay";
import { PulseLoader } from "react-spinners";
import { capitalizeFirstLetter, CurrencyFormating } from "../../helpers/currencyHelper";
import DataTable from "react-data-table-component";

import Decimal from 'decimal.js';



export default function ReportBalanceBlockchain() {
  const token = useSelector((state) => state.setToken);
  const [loading, setLoading] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [balances, setBalance] = useState({});
  const [lowFund, setLow] = useState(false);

  const [limit, setLimit] = useState(50);
  const [page, setPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);

  const columns = [
    {
      name: "Anount",
      selector: (row) => row.amount,
      // reorder: true,
      width: "200px",
    },
    {
      name: "To Address",
      selector: (row) => row.to_address,
      // reorder: true,
      width: "300px",
    },
    {
      name: "Fee",
      selector: (row) => row.fee_amount,
      // reorder: true,
      width: "150px",
    },
    {
      name: "Confirmed",
      selector: (row) => row.confirmed,
      // reorder: true,
      width: "100px",
    },
    {
      name: "Confirmed Height",
      selector: (row) => row.confirmed_at_height,
      // reorder: true,
      width: "150px",
    },
    {
      name: "Date",
      selector: (row) => row.date,
      // reorder: true,
      width: "150px",
    }
  ];
  const customStyles = {
    rows: {
      style: {
        //minHeight: '72px',
        backgroundColor: "transparent",
        color: "#fff",
        paddingLeft: "10px", // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: "17px", // override the cell padding for head cells
        paddingRight: "2px",
        backgroundColor: "transparent",
        color: "#fff",
        fontSize: "16px",
      },
    },
    cells: {
      style: {
        paddingLeft: "4px", // override the cell padding for data cells
        paddingRight: "2px",
        //   paddingTop: "5px",
        paddingTop: "25px",
        //   paddingBottom: "5px",
        paddingBottom: "25px",
        backgroundColor: "transparent",
        color: "#fff",
        fontSize: "14px",
      },
    },
  };



  const transactionsData = () => {
    let transactions_data = [];
    transactions?.map((item, index) => {
      transactions_data.push({
        amount: `${item.type == 0? '+' : "-"} ${item.amount} mojo`,
        to_address: item.to_address,
        fee_amount: item.fee_amount + " mojo",
        confirmed: item.confirmed? 'True' : 'False',
        confirmed_at_height: item.confirmed_at_height,
        date: moment.unix(item.created_at_time).format("MM-DD-YYYY hh:mm a")
      });
    });
    return transactions_data;
  };
  const getbalance = async () => {
    await axios.get(`${process.env.REACT_APP_BLOCKCHAIN_URL}/get-wallet-balance?wallet_id=2`)
      .then((res) => {
        if (res.data.success === true) {
          let { wallet_balances} = res.data
          setBalance({ balance:wallet_balances["1"].confirmed_wallet_balance });
          if(wallet_balances["1"].confirmed_wallet_balance<16600000000)
          {
            setLow(true)
          }
          
        }
      })
      .catch((err) => {
        console.log("getReports balance", err);
      });
  };

  const getTransactions = async () => {

    let start = (page-1) * limit
    let end = (page) * limit
    await axios.get(`${process.env.REACT_APP_BLOCKCHAIN_URL}/get-transactions?wallet_id=1&start=${start}&end=${end}`)
      .then((res) => {
        if (res.data.success === true) {
          setTransactions(res.data.transactions);
          setTotalRows(res.data.count)
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    setLoading(true)
    Promise.all([getTransactions(), getbalance()]).then(() => {
      setLoading(false)
    })


  }, [limit,page])



  const EmptyDataLoader = () => (
    <div
      style={{
        padding: "15px",
        background: "#495A71",
        color: "#fff",
        width: "100%",
        fontSize: "40px",
        textAlign: "center",
        minHeight: "350px",
        lineHeight: "400px",
      }}
    >
      <div>No data found...</div>
    </div>
  );

  const handlePerRowsChange = (newPerPage) => {
    setLimit(newPerPage);
    setPage(1);
  };
  const changePage = (page) => {
    setPage(page);
  };


  return (
    <div className="home_new">
      <div className="dash_partts_early">
        <div className="dash_part_heading">
          <div className="dash_part_heading_left">
            <h2>Blockchain Balance Report</h2>
          </div>
        </div>
      </div>
      <LoadingOverlay
        active={loading}
        content={()=>{}}
        spinner={<PulseLoader color="white" size={10} />}
      >
        <div className="dash_partts_inner">
          {lowFund && <div className="alertReportMsg">
            
              <div className="d-flex align-items-start flex-column flex-c">
                <span className="text-center" style={{ fontSize: "18px",color:"#fff" }}>Alert:</span>
                <span className=""style={{ fontSize: "16px",color:"#fff" }}>NFT minting at blockchain will be stop due to low balance at blockchain wallet. please add some mojos at blockchain wallet.</span>
              </div>
              
            
          </div>}

          <div className="info_block mt-5 mb-4">
            <Row>
              <Col lg={12} sm={12} className="">
                <div className="item_info_balancereport">
                  <Row>
                    <Col sm={8} className="align-items-start col-sm-8 d-flex flex-column justify-content-center">

                      <div className="balance-r-value" >{(balances?.balance ?? 0)}</div>
                      <span className="balance-r-title" >Mojo Balance</span>

                    </Col>
                    <Col sm={4}>
                      <img style={{maxHeight:"150px"}} src="data:image/svg+xml;base64,PCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4KDTwhLS0gVXBsb2FkZWQgdG86IFNWRyBSZXBvLCB3d3cuc3ZncmVwby5jb20sIFRyYW5zZm9ybWVkIGJ5OiBTVkcgUmVwbyBNaXhlciBUb29scyAtLT4KPHN2ZyBmaWxsPSIjMjgzMzQyIiB3aWR0aD0iODAwcHgiIGhlaWdodD0iODAwcHgiIHZpZXdCb3g9IjAgLTggNzIgNzIiIGlkPSJMYXllcl8xIiBkYXRhLW5hbWU9IkxheWVyIDEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+Cg08ZyBpZD0iU1ZHUmVwb19iZ0NhcnJpZXIiIHN0cm9rZS13aWR0aD0iMCIvPgoNPGcgaWQ9IlNWR1JlcG9fdHJhY2VyQ2FycmllciIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cg08ZyBpZD0iU1ZHUmVwb19pY29uQ2FycmllciI+Cg08dGl0bGU+bGVhZjwvdGl0bGU+Cg08cGF0aCBkPSJNNDguNjgsMzguNzFjOC4wNi04LjA2LDEwLTM0LjU5LDEwLTM0LjU5QTQwLjk0LDQwLjk0LDAsMCwxLDQ0LjQxLDcuNzNDMzYuMTYsOC40MywyOC41Miw5LjYsMjQsMTQuMDgsMTcuMTMsMjEsMTUuNCwzMC44MiwxOS4zNCwzNy43NywyMy42NiwzMSw0MS40NywyMS4yOSw0MS40NywyMS4yOSwyOC42OSwzMC40MywxNCw0Ny43OCwxMy40MSw1MHMzLjY0LDIuMyw0LjMsMS4xNSwzLjItNS43OCw2LTguNTdDMzAuNjgsNDcuNTMsNDEuMzMsNDYuMDcsNDguNjgsMzguNzFaIi8+Cg08L2c+Cg08L3N2Zz4=" className="img-fluid">
                      </img></Col>
                  </Row>

                </div>


              </Col>
            </Row>
          </div>

          <div className="dash_part_heading">
            <div className="dash_part_heading_left">
              <h2>Blockchain Wallet Transactions</h2>
            </div>
          </div>

          <div className="info_block d-flex flex-wrap mt-5 mb-4">
            <DataTable
              className="hypTable1"
              columns={columns}
              data={transactionsData()}
              responsive
              customStyles={customStyles}
              defaultSortField="id"
              defaultSortAsc={false}
              noDataComponent={<EmptyDataLoader />}
              pagination
            paginationServer
            paginationTotalRows={Number(totalRows)}
            paginationPerPage={Number(limit)}
            paginationRowsPerPageOptions={[50,75,100]}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={changePage}
            />
          </div>
        </div>


      </LoadingOverlay>
    </div>
  );
}
