import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "../../axios/axios";
import message from "../../messages";
import { Card, Col, Container, Row } from "react-bootstrap";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import LoadingOverlay from "react-loading-overlay";
import { PulseLoader } from "react-spinners";
import { Bar, Line, Pie } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  LineElement,
  ArcElement,
} from "chart.js";

// Register required chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  LineElement,
  ArcElement
);

const fullMonths = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export default function ReportDashboard() {
  const token = useSelector((state) => state.setToken);
  const [loading, setLoading] = useState(true);
  const [registerUserData, setRegisterUserData] = useState({
    data: [],
    labels: [],
  });
  const [mintedCardData, setMintedCardData] = useState({
    data: [],
    labels: [],
  });
  const [firstSaleData, setFirstSaleData] = useState({
    data: [],
    labels: [],
  });
  const [secondSaleData, setSecondSaleData] = useState({
    data: [],
    labels: [],
  });
  const [totalTransactionData, setTotalTransactionData] = useState({
    totalAmount: [],
    totalHypeuAmount: [],
    totalSellerAmount: [],
    totalMintorAmount: [],
    labels: [],
  });
  const [topCreatorMinterCount, setTopCreatorMinterCount] = useState([]);
  const [topCreatorMinterPrice, setTopCreatorMinterPrice] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [selectedStartDate, setSelectedStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedEndDate, setSelectedEndDate] = useState("");

  const getRegisterUserChart = () => {
    // Check if both startDate and endDate are either both set or both empty
    const bothEmptyOrBothSet =
      (startDate && endDate) || (!startDate && !endDate);
    if (!bothEmptyOrBothSet) {
      return;
    }

    setLoading(true);
    axios
      .get(`/register-user-chart?startDate=${startDate}&endDate=${endDate}`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        setLoading(false);
        if (res.data.success) {
          const { reports } = res.data;
          const labels = [];
          const data = reports.map(({ count, monthName, year }) => {
            labels.push(monthName + "-" + (year % 100));
            return count;
          });

          registerUserData.data = [...data];
          registerUserData.labels = [...labels];

          setRegisterUserData({ ...registerUserData });
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.error("err", err);
        message.error("Something went wrong");
      });
  };

  const getMintedCardChart = () => {
    // Check if both startDate and endDate are either both set or both empty
    const bothEmptyOrBothSet =
      (startDate && endDate) || (!startDate && !endDate);
    if (!bothEmptyOrBothSet) {
      return;
    }

    setLoading(true);
    axios
      .get(`/minted-card-chart?startDate=${startDate}&endDate=${endDate}`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        setLoading(false);
        if (res.data.success) {
          const { reports } = res.data;
          const labels = [];
          const data = reports.map(({ count, monthName, year }) => {
            labels.push(monthName + "-" + (year % 100));
            return count;
          });

          mintedCardData.data = [...data];
          mintedCardData.labels = [...labels];
          setMintedCardData({ ...mintedCardData });
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.error("err", err);
        message.error("Something went wrong");
      });
  };

  const getFirstSaleChart = () => {
    // Check if both startDate and endDate are either both set or both empty
    const bothEmptyOrBothSet =
      (startDate && endDate) || (!startDate && !endDate);
    if (!bothEmptyOrBothSet) {
      return;
    }

    setLoading(true);
    axios
      .get(`/first-sale-chart?startDate=${startDate}&endDate=${endDate}`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        setLoading(false);
        if (res.data.success) {
          const { reports } = res.data;
          const labels = [];
          const data = reports.map(({ count, monthName, year }) => {
            labels.push(monthName + "-" + (year % 100));
            return count;
          });

          firstSaleData.data = [...data];
          firstSaleData.labels = [...labels];
          setFirstSaleData({ ...firstSaleData });
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.error("err", err);
        message.error("Something went wrong");
      });
  };

  const getSecondSaleChart = () => {
    // Check if both startDate and endDate are either both set or both empty
    const bothEmptyOrBothSet =
      (startDate && endDate) || (!startDate && !endDate);
    if (!bothEmptyOrBothSet) {
      return;
    }

    setLoading(true);
    axios
      .get(`/second-sale-chart?startDate=${startDate}&endDate=${endDate}`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        setLoading(false);
        if (res.data.success) {
          const { reports } = res.data;
          const labels = [];
          const data = reports.map(({ count, monthName, year }) => {
            labels.push(monthName + "-" + (year % 100));
            return count;
          });

          secondSaleData.data = [...data];
          secondSaleData.labels = [...labels];
          setSecondSaleData({ ...secondSaleData });
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.error("err", err);
        message.error("Something went wrong");
      });
  };

  const getTotalTransactionChart = () => {
    // Check if both startDate and endDate are either both set or both empty
    const bothEmptyOrBothSet =
      (startDate && endDate) || (!startDate && !endDate);
    if (!bothEmptyOrBothSet) {
      return;
    }

    setLoading(true);
    axios
      .get(
        `/total-transaction-chart?startDate=${startDate}&endDate=${endDate}`,
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((res) => {
        setLoading(false);
        if (res.data.success) {
          const { reports } = res.data;
          const labels = [];
          const totalAmountData = [];
          const totalHypeuAmountData = [];
          const totalMintorAmountData = [];
          const totalSellerAmount = reports.map(
            ({
              totalSellerAmount,
              monthName,
              year,
              totalAmount,
              totalHypeuAmount,
              totalMintorAmount,
            }) => {
              labels.push(monthName + "-" + (year % 100));
              totalAmountData.push(totalAmount);
              totalHypeuAmountData.push(totalHypeuAmount);
              totalMintorAmountData.push(totalMintorAmount);
              return totalSellerAmount;
            }
          );

          totalTransactionData.totalSellerAmount = [...totalSellerAmount];
          totalTransactionData.totalAmount = [...totalAmountData];
          totalTransactionData.totalHypeuAmount = [...totalHypeuAmountData];
          totalTransactionData.totalMintorAmount = [...totalMintorAmountData];
          totalTransactionData.labels = [...labels];
          setTotalTransactionData({ ...totalTransactionData });
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.error("err", err);
        message.error("Something went wrong");
      });
  };

  const getTopMinterCountChart = () => {
    const bothEmptyOrBothSet =
      (startDate && endDate) || (!startDate && !endDate);
    if (!bothEmptyOrBothSet) {
      return;
    }

    setLoading(true);
    axios
      .get(
        `/top-minter-count-chart?startDate=${startDate}&endDate=${endDate}`,
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((res) => {
        setLoading(false);
        if (res.data.success) {
          const { reports } = res.data;
          let monthName;

          const topCreator = reports.map(({ month, year, users }) => {
            monthName = fullMonths[month - 1] + "-" + (year % 100);
            const labels = [];
            const data = users.map(({ name, totalNFTCount }) => {
              labels.push(name);
              return totalNFTCount;
            });

            return {
              label: monthName,
              users: {
                labels,
                data,
              },
            };
          });
          setTopCreatorMinterCount(topCreator);
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.error("err", err);
        message.error("Something went wrong");
      });
  };

  const getTopMinterPriceChart = () => {
    const bothEmptyOrBothSet =
      (startDate && endDate) || (!startDate && !endDate);
    if (!bothEmptyOrBothSet) {
      return;
    }

    setLoading(true);
    axios
      .get(
        `/top-minter-price-chart?startDate=${startDate}&endDate=${endDate}`,
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((res) => {
        setLoading(false);
        if (res.data.success) {
          const { reports } = res.data;
          let monthName;

          const topCreator = reports.map(({ month, year, users }) => {
            monthName = fullMonths[month - 1] + "-" + (year % 100);
            const labels = [];
            const data = users.map(({ name, totalNFTPrice }) => {
              labels.push(name);
              return totalNFTPrice;
            });

            return {
              label: monthName,
              users: {
                labels,
                data,
              },
            };
          });
          setTopCreatorMinterPrice(topCreator);
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.error("err", err);
        message.error("Something went wrong");
      });
  };

  useEffect(() => {
    getRegisterUserChart();
    getMintedCardChart();
    getFirstSaleChart();
    getSecondSaleChart();
    getTotalTransactionChart();
    getTopMinterCountChart();
    getTopMinterPriceChart();
  }, [token, startDate, endDate]);

  const userRegisterDatasets = {
    labels: registerUserData.labels,
    datasets: [
      {
        label: "User Registrations",
        data: registerUserData.data,
        backgroundColor: "rgba(75, 192, 192, 0.6)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
        barThickness: 40,
      },
    ],
  };

  const userRegisterOptions = {
    responsive: true,
    maintainAspectRatio: false, // Allow manual control over height and width
    plugins: {
      legend: {
        position: "top",
        labels: {
          boxWidth: 10, // Smaller legend boxes
          color: "white", // Set legend label color to white
        },
      },
      title: {
        display: true,
        text: "Monthly User Registrations",
        color: "white", // Set title color to white
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Months",
          color: "white", // Set x-axis title color to white
        },
        ticks: {
          font: {
            size: 10, // Smaller font size for x-axis labels
          },
          color: "white", // Set x-axis tick label color to white
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Number of Registrations",
          color: "white", // Set y-axis title color to white
        },
        ticks: {
          font: {
            size: 10, // Smaller font size for y-axis labels
          },
          color: "white", // Set y-axis tick label color to white
        },
      },
    },
  };

  const generateColors = (numColors) => {
    const colors = [];
    for (let i = 0; i < numColors; i++) {
      const color = `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(
        Math.random() * 255
      )}, ${Math.floor(Math.random() * 255)}, 0.6)`;
      colors.push(color);
    }
    return colors;
  };

  const mintedCardDatasets = {
    labels: mintedCardData.labels,
    datasets: [
      {
        label: "Card Minted",
        data: mintedCardData.data,
        backgroundColor: generateColors(mintedCardData.data.length),
        borderColor: generateColors(mintedCardData.data.length).map((color) =>
          color.replace("0.6", "1")
        ),
        borderWidth: 1,
      },
    ],
  };

  const mintedCardOptions = {
    responsive: true,
    maintainAspectRatio: false, // Allow manual control over height and width
    plugins: {
      legend: {
        labels: {
          color: "white", // Set the legend text color to white
        },
      },
      title: {
        display: true,
        text: "Monthly Minted Cards",
        color: "white",
      },
    },

    // plugins: {
    //   legend: {
    //     labels: {
    //       color: 'white', // Set the legend text color to white
    //     },
    //   },
    // },
    // scales: {
    //   x: {
    //     title: {
    //       display: true,
    //       text: "Months",
    //       color: "white", // Set x-axis title color to white
    //     },
    //     ticks: {
    //       font: {
    //         size: 10, // Smaller font size for x-axis labels
    //       },
    //       color: "white", // Set x-axis tick label color to white
    //     },
    //   },
    //   y: {
    //     beginAtZero: true,
    //     title: {
    //       display: true,
    //       text: "Number of Cards",
    //       color: "white", // Set y-axis title color to white
    //     },
    //     ticks: {
    //       font: {
    //         size: 10, // Smaller font size for y-axis labels
    //       },
    //       color: "white", // Set y-axis tick label color to white
    //     },
    //   },
    // },
  };

  const firstSaleDatasets = {
    labels: firstSaleData.labels,
    datasets: [
      {
        label: "First Sale",
        data: firstSaleData.data,
        fill: false,
        borderColor: "rgba(140, 249, 180, 1)",
        tension: 0.1,
        borderWidth: 2,
        pointRadius: 5,
        pointBackgroundColor: "rgba(140, 249, 180, 1)",
      },
      {
        label: "Second Sale",
        data: secondSaleData.data,
        fill: false,
        borderColor: "rgba(253, 249, 106, 1)",
        tension: 0.1,
        borderWidth: 2,
        pointRadius: 5,
        pointBackgroundColor: "rgba(253, 249, 106, 1)",
      },
    ],
  };

  const firstSaleOptions = {
    responsive: true,
    maintainAspectRatio: false, // Allow manual control over height and width
    // plugins: {
    //   legend: {
    //     position: "top",
    //     labels: {
    //       boxWidth: 10, // Smaller legend boxes
    //       color: "white", // Set legend label color to white
    //     },
    //   },
    //   title: {
    //     display: true,
    //     text: "Monthly First Sale",
    //     color: "white", // Set title color to white
    //   },
    // },
    // scales: {
    //   x: {
    //     title: {
    //       display: true,
    //       text: "Months",
    //       color: "white", // Set x-axis title color to white
    //     },
    //     ticks: {
    //       font: {
    //         size: 10, // Smaller font size for x-axis labels
    //       },
    //       color: "white", // Set x-axis tick label color to white
    //     },
    //   },
    //   y: {
    //     beginAtZero: true,
    //     title: {
    //       display: true,
    //       text: "Number of Sales",
    //       color: "white", // Set y-axis title color to white
    //     },
    //     ticks: {
    //       font: {
    //         size: 10, // Smaller font size for y-axis labels
    //       },
    //       color: "white", // Set y-axis tick label color to white
    //     },
    //   },
    // },
    plugins: {
      legend: {
        position: "top",
        labels: {
          color: "white",
        },
      },
      title: {
        display: true,
        text: "Monthly Sales",
        color: "white",
      },
    },
    scales: {
      x: {
        ticks: {
          color: "white",
        },
      },
      y: {
        ticks: {
          color: "white",
        },
      },
    },
  };

  const totalTransactionDatasets = {
    labels: totalTransactionData.labels,
    datasets: [
      {
        label: "Total Amount",
        data: totalTransactionData.totalAmount,
        fill: false,
        borderColor: "rgba(253, 249, 106, 1)",
        tension: 0.1,
        borderWidth: 2,
        pointRadius: 5,
        pointBackgroundColor: "rgba(253, 249, 106, 1)",
      },
      {
        label: "Total Hypeu Amount",
        data: totalTransactionData.totalHypeuAmount,
        fill: false,
        borderColor: "rgba(153, 58, 162, 1)",
        tension: 0.1,
        borderWidth: 2,
        pointRadius: 5,
        pointBackgroundColor: "rgba(153, 58, 162, 1)",
      },
      {
        label: "Total Seller Amount",
        data: totalTransactionData.totalSellerAmount,
        fill: false,
        borderColor: "rgba(140, 249, 180, 1)",
        tension: 0.1,
        borderWidth: 2,
        pointRadius: 5,
        pointBackgroundColor: "rgba(140, 249, 180, 1)",
      },
      {
        label: "Total Mintor Amount",
        data: totalTransactionData.totalMintorAmount,
        fill: false,
        borderColor: "rgba(147, 131, 243, 1)",
        tension: 0.1,
        borderWidth: 2,
        pointRadius: 5,
        pointBackgroundColor: "rgba(147, 131, 243, 1)",
      },
    ],
  };

  const totalTransactionOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
        labels: {
          color: "white",
        },
      },
      title: {
        display: true,
        text: "Monthly Total Transaction & Hypeu Share",
        color: "white",
      },
    },
    scales: {
      x: {
        ticks: {
          color: "white",
        },
      },
      y: {
        ticks: {
          color: "white",
        },
      },
    },
  };

  return (
    <div className="home_new">
      <LoadingOverlay
        active={loading}
        spinner={<PulseLoader color="white" size={10} />}
      >
        <div className="dash_partts_early">
          <div className="dash_part_heading">
            <div className="dash_part_heading_left">
              <h2>Report Dashboard</h2>
            </div>
          </div>
        </div>

        <div className="dash_partts_inner mb-5">
          <Card className="filter_card mt-3" style={{ overflow: "visible" }}>
            <Card.Body>
              <Container>
                <Row>
                  <Col md={12}>
                    <Row>
                      <Col md={4}>
                        <label className="form-label" htmlFor="startDate">
                          Start Date
                        </label>

                        <DatePicker
                          id="startDate"
                          className="form-control"
                          type="date"
                          selected={selectedStartDate || ""}
                          onChange={(e) => {
                            setStartDate(moment(e).format("YYYY-MM-DD"));
                            setSelectedStartDate(e);
                          }}
                          // minDate={selectedStartDate || ""}
                          maxDate={selectedEndDate || new Date()}
                          placeholderText="YYYY-MM-DD"
                          dateFormat={"yyyy-MM-dd"}
                        />
                      </Col>
                      <Col md={4}>
                        <label className="form-label" htmlFor="endDate">
                          End Date
                        </label>

                        <DatePicker
                          id="endDate"
                          className="form-control"
                          type="date"
                          selected={selectedEndDate || ""}
                          onChange={(e) => {
                            setEndDate(moment(e).format("YYYY-MM-DD"));
                            setSelectedEndDate(e);
                          }}
                          minDate={selectedStartDate || ""}
                          maxDate={new Date()}
                          placeholderText="YYYY-MM-DD"
                          dateFormat={"yyyy-MM-dd"}
                        />
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <label className="form-label">&nbsp;</label>
                          <button
                            className="form-control filter_submit btn"
                            onClick={() => {
                              setEndDate("");
                              setSelectedEndDate("");
                              setStartDate("");
                              setSelectedStartDate("");
                            }}
                          >
                            Clear
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Container>
            </Card.Body>
          </Card>

          <Row>
            <Col lg={12} md={12}>
              <div
                className="manage_table "
                style={{ height: "400px", padding: "30px" }}
              >
                <Bar
                  data={userRegisterDatasets}
                  options={userRegisterOptions}
                />
              </div>
            </Col>
            <Col lg={6} md={12}>
              <div
                className="manage_table "
                style={{ height: "400px", padding: "30px" }}
              >
                <Line data={firstSaleDatasets} options={firstSaleOptions} />
              </div>
            </Col>
            <Col lg={6} md={12}>
              <div className="manage_table " style={{ padding: "30px" }}>
                <Pie
                  data={mintedCardDatasets}
                  options={mintedCardOptions}
                  width={300}
                  height={340}
                />
              </div>
            </Col>
            <Col lg={12} md={12}>
              <div
                className="manage_table "
                style={{ height: "400px", padding: "30px" }}
              >
                <Line
                  data={totalTransactionDatasets}
                  options={totalTransactionOptions}
                />
              </div>
            </Col>
          </Row>

          <Row>
            {topCreatorMinterCount.map((item, key) => {
              const datasets = {
                labels: item?.users?.labels,
                datasets: [
                  {
                    label: "Top 3 Minter Based on Transaction Count",
                    data: item?.users?.data,
                    backgroundColor: "rgba(75, 192, 192, 0.6)",
                    borderColor: "rgba(75, 192, 192, 1)",
                    borderWidth: 1,
                    barThickness: 40,
                  },
                ],
              };

              const options = {
                responsive: true,
                maintainAspectRatio: false,

                plugins: {
                  legend: {
                    position: "top",
                    labels: {
                      boxWidth: 10,
                      color: "white",
                    },
                  },
                  title: {
                    display: true,
                    text: item?.label,
                    color: "white",
                  },
                },
                scales: {
                  x: {
                    title: {
                      display: true,
                      text: "Months",
                      color: "white",
                    },
                    ticks: {
                      font: {
                        size: 10,
                      },
                      color: "white",
                    },
                  },
                  y: {
                    beginAtZero: true,
                    title: {
                      display: true,
                      text: "Count",
                      color: "white",
                    },
                    ticks: {
                      font: {
                        size: 10,
                      },
                      color: "white",
                    },
                  },
                },
              };

              return (
                <Col lg={3} md={12} key={key}>
                  <div
                    className="manage_table "
                    style={{ height: "400px", padding: "30px" }}
                  >
                    <Bar data={datasets} options={options} />
                  </div>
                </Col>
              );
            })}
          </Row>

          <Row>
            {topCreatorMinterPrice.map((item, key) => {
              const datasets = {
                labels: item?.users?.labels,
                datasets: [
                  {
                    label: "Top 3 Minter Based on Transaction Price",
                    data: item?.users?.data,
                    backgroundColor: "rgba(75, 192, 192, 0.6)",
                    borderColor: "rgba(75, 192, 192, 1)",
                    borderWidth: 1,
                    barThickness: 40,
                  },
                ],
              };

              const options = {
                responsive: true,
                maintainAspectRatio: false,

                plugins: {
                  legend: {
                    position: "top",
                    labels: {
                      boxWidth: 10,
                      color: "white",
                    },
                  },
                  title: {
                    display: true,
                    text: item?.label,
                    color: "white",
                  },
                },
                scales: {
                  x: {
                    title: {
                      display: true,
                      text: "Months",
                      color: "white",
                    },
                    ticks: {
                      font: {
                        size: 10,
                      },
                      color: "white",
                    },
                  },
                  y: {
                    beginAtZero: true,
                    title: {
                      display: true,
                      text: "Price",
                      color: "white",
                    },
                    ticks: {
                      font: {
                        size: 10,
                      },
                      color: "white",
                    },
                  },
                },
              };

              return (
                <Col lg={3} md={12} key={key}>
                  <div
                    className="manage_table "
                    style={{ height: "400px", padding: "30px" }}
                  >
                    <Bar data={datasets} options={options} />
                  </div>
                </Col>
              );
            })}
          </Row>
        </div>
      </LoadingOverlay>
    </div>
  );
}
