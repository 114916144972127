import { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import LoadingOverlay from "react-loading-overlay";
import Item from "../HypeuTotals/cardinfo/item";
import { CurrencyFormating } from "../../helpers/currencyHelper";
import { PulseLoader } from "react-spinners";
import { useSelector } from "react-redux";
import axios from "../../axios/axios";
import message from "../../messages";
import DataTable from "react-data-table-component";
import moment from "moment";
import { No_ProfImg } from "../../assets/images";
import "./report.css";
import { Link } from "react-router-dom";

export default function DailyReport() {
  const token = useSelector((state) => state.setToken);

  // Create a reference for the section
  const sectionRef = useRef(null);

  // Function to scroll to the section
  const scrollToSection = () => {
    sectionRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const [loading, setLoading] = useState(true);
  const [totals, setTotals] = useState({});
  const [clickedKey, setClickedKey] = useState("");
  const [clickedTitle, setClickedTitle] = useState("");
  const [specificData, setSpecificData] = useState([]);

  const getDailyReports = () => {
    setLoading(true);

    axios
      .get("/daily-reports", {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        setLoading(false);
        if (res.data.success) {
          setTotals(res.data.counts);
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.error("err", err);
        message.error("Something went wrong");
      });
  };

  useEffect(() => {
    getDailyReports();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const COLUMNS = [
    {
      name: "Author of NFTs",
      selector: (row) => row?.creator?.name,
      width: "250px",
    },
    {
      name: "Total NFTs",
      selector: (row) => row?.NFT_Count,
      width: "200px",
    },
    {
      name: "Total Edition",
      selector: (row) => row?.editionTotal,
      width: "200px",
    },
    {
      name: "NFT Types",
      selector: (row) => (
        <>
          {row?.nftTypes?.map((item, key) => {
            return (
              <div className="nft_type" key={key}>
                {item.name} : {item.count}
              </div>
            );
          })}
        </>
      ),
    },
  ];

  const createdUserColumn = [
    {
      name: "Name",
      selector: ({ name, _id, profileDetails }) => (
        <div className="management_list_name">
          <img
            className="reporter_division_image"
            src={
              profileDetails?.thumbnailUrl
                ? profileDetails?.thumbnailUrl
                : No_ProfImg
            }
            alt={name}
            loading="lazy"
          />
          <Link target="_blank" to={`/userdetails/${_id}`} className="reporter_divsor_name">
            {name}
          </Link>
        </div>
      ),
      width: "350px",
    },
    {
      name: "Username",
      selector: (row) => (
        <Link target="_blank" to={`/userdetails/${row._id}`} className="reporter_username">
          @{row.userName}
        </Link>
      ),
      width: "200px",
    },
    {
      name: "email",
      selector: (row) => <div>{row.email}</div>,
      width: "300px",
    },
    {
      name: "Account Created",
      selector: (row) => moment(row.createdAt).format("YYYY-MM-DD"),
      width: "250px",
    },
    {
      name: "Status",
      selector: (row) => (row.status ? "Active" : "Inactive"),
      width: "250px",
    },
  ];

  const LoggedinColumn = [
    {
      name: "Name",
      selector: ({ userDetails }) => (
        <div className="management_list_name">
          <div className="man_user story_user division_image">
            <img
              src={
                userDetails?.thumbnailUrl
                  ? userDetails?.thumbnailUrl
                  : No_ProfImg
              }
              alt={userDetails?.name}
              loading="lazy"
            />
          </div>

          <Link target="_blank" to={`/userdetails/${userDetails?._id}`}
            className="reporter_divsor_name man_text story_text"
            style={{ marginLeft: "15px" }}
          >
            {userDetails?.name}
          </Link>
        </div>
      ),
      width: "350px",
    },
    {
      name: "Username",
      selector: ({ userDetails }) => (
        <Link target="_blank" to={`/userdetails/${userDetails?._id}`}
          className="reporter_divsor_name man_username"
        >
          @{userDetails?.userName}
        </Link>
      ),
      width: "200px",
    },
    {
      name: "email",
      selector: ({ userDetails }) => <div>{userDetails.email}</div>,
      width: "300px",
    },
    {
      name: "Account Created",
      selector: ({ userDetails }) =>
        moment(userDetails?.createdAt).format("YYYY-MM-DD"),
      width: "200px",
    },
    {
      name: "Login Date",
      selector: (row) => moment(row.createdAt).format("YYYY-MM-DD"),
      width: "200px",
    },
  ];

  const cardMintedColumn = [
    {
      name: "",
      selector: (row) => (
        <div className="man_user">
          <img src={row?.fileUrl} alt={row.title} />
        </div>
      ),
      width: "50px",
    },
    {
      name: "Name of NFTs",
      selector: (row) => (
        <div className="management_list_name">
          <Link target="_blank" to={`/nftdetails/${row._id}/${row.NFT_identity}`}
            className="reporter_divsor_name man_text"
          >
            {row.title || row?.creator?.nickName}
          </Link>
        </div>
      ),
      width: "200px",
    },
    {
      name: "Author of NFTs",
      selector: ({ creator }) => (
          <Link target="_blank" to={`/userdetails/${creator?._id}`}
            className="reporter_divsor_name "
          >
            <div className="man_email">
            {creator?.name}
            <p>
              <small>@{creator?.userName}</small>
            </p>
            </div>
          </Link>
      ),
      width: "250px",
    },
    {
      name: "NFT Holder",
      selector: ({ nftHolder }) => (
        <Link target="_blank" to={`/userdetails/${nftHolder?._id}`}
            className="reporter_divsor_name"
          >
          <div className="man_email">
           {nftHolder?.name}
          <p>
            <small>@{nftHolder?.userName}</small>
          </p>
          </div>
        </Link>
      ),
      width: "250px",
    },
    {
      name: "NFT Type",
      selector: (row) => row.type || row?.nftType?.name,
      width: "250px",
    },
  ];

  const cardFirstSaleColumn = [
    {
      name: "",
      selector: ({ nft }) => (
        <div className="man_user">
          <img src={nft?.fileUrl} alt={nft.title} />
        </div>
      ),
      width: "50px",
    },
    {
      name: "Name of NFTs",
      selector: ({ nft }) => (
        <Link target="_blank" to={`/nftdetails/${nft._id}/${nft.NFT_identity}`}
            className="management_list_name"
          >
            
          <div className="man_text">{nft.title || nft?.creator?.nickName}</div>
        
          </Link>
        
      ),
      width: "250px",
    },
    {
      name: "Edition",
      selector: ({ nft }) => nft?.editions,
      width: "100px",
    },
    {
      name: "Price",
      selector: (row) => row?.price,
      width: "100px",
    },
    {
      name: "Seller",
      selector: ({ sellerDetails }) => (<Link target="_blank" to={`/userdetails/${sellerDetails?._id}`}
        className="reporter_divsor_name "
      >
        <div className="man_email">
        {sellerDetails?.name}
        <p>
          <small>@{sellerDetails?.userName}</small>
        </p>
        </div>
      </Link>),
      width: "150px",
    },
    {
      name: "Buyer",
      selector: ({ buyerDetails }) =>(<Link target="_blank" to={`/userdetails/${buyerDetails?._id}`}
        className="reporter_divsor_name "
      >
        <div className="man_email">
        {buyerDetails?.name}
        <p>
          <small>@{buyerDetails?.userName}</small>
        </p>
        </div>
      </Link>),
      width: "150px",
    },
    {
      name: "Seller (%)",
      selector: ({ commission }) => commission?.seller?.percentage + "%",
      width: "100px",
    },
    {
      name: "Seller ($)",
      selector: ({ commission }) =>
        CurrencyFormating(commission?.seller?.amount || 0),
      width: "100px",
    },

    {
      name: "Mintor (%)",
      selector: ({ commission }) => commission?.mintor?.percentage + "%",
      width: "100px",
    },
    {
      name: "Mintor ($)",
      selector: ({ commission }) =>
        CurrencyFormating(commission?.mintor?.amount || 0),
      width: "100px",
    },
    {
      name: "Hypeu (%)",
      selector: ({ commission }) => commission?.hypeu?.percentage + "%",
      width: "100px",
    },
    {
      name: "Hypeu ($)",
      selector: ({ commission }) =>
        CurrencyFormating(commission?.hypeu?.amount || 0),
      width: "100px",
    },
  ];

  const onItemClicked = (key, title) => {
    scrollToSection();
    setClickedKey(key);
    setClickedTitle(title);
    getDailyReportSpecificData(key);
  };

  const getDailyReportSpecificData = (key) => {
    setLoading(true);

    axios
      .get(`/daily-reports/${key}`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        setLoading(false);
        if (res.data.success) {
          setSpecificData(res.data.data);
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.error("err", err);
        message.error("Something went wrong");
      });
  };

  const getColumns = (key) => {
    switch (key) {
      case "created_user":
        return createdUserColumn;
      case "loggedin_user":
        return LoggedinColumn;
      case "card_minted":
        return cardMintedColumn;
      case "edition_minted":
        return cardMintedColumn;
      case "card_first_sale":
        return cardFirstSaleColumn;
      case "card_second_sale":
        return cardFirstSaleColumn;
      case "total_card_sale":
        return cardFirstSaleColumn;

      default:
        return COLUMNS;
    }
  };

  return (
    <div className="home_new">
      <div className="dash_partts_early">
        <div className="dash_part_heading">
          <div className="dash_part_heading_left">
            <h2>Daily Report</h2>
          </div>
        </div>
      </div>

      <div className="dash_partts_inner">
        <Row>
          <Col md={12}>
            <LoadingOverlay
              active={loading}
              spinner={<PulseLoader color="white" size={10} />}
            >
              <div className="info_block d-flex flex-wrap mt-5 mb-4 report_block">
                <Item
                  value={totals.createdUsers || 0}
                  title={`Created Users`}
                  desc="New users registered total."
                  className="mt-2 reporting"
                  onClick={() => {
                    onItemClicked("created_user", "Created Users");
                  }}
                />

                <Item
                  value={totals.loggedinUsers || 0}
                  title={`Users Loggedin`}
                  desc="New loggedin users total."
                  className="mt-2 reporting"
                  onClick={() => {
                    onItemClicked("loggedin_user", "Users Loggedin");
                  }}
                />
                <Item
                  value={totals.cardsMinted || 0}
                  title={`Cards Minted`}
                  desc="New nft cards minted total."
                  className="mt-2 reporting"
                  onClick={() => {
                    onItemClicked("card_minted", "Cards Minted");
                  }}
                />

                <Item
                  value={totals.editionMinted || 0}
                  title={`Editions Minted`}
                  desc="New minted cards editions total"
                  className="mt-2 reporting"
                  onClick={() => {
                    onItemClicked("edition_minted", "Editions Minted");
                  }}
                />
                <Item
                  value={totals.newEditionSoldCount || 0}
                  title={`Card First Sale`}
                  desc="Cards first sale ( from minter to buyer directly ) total count."
                  className="mt-2 reporting"
                  onClick={() => {
                    onItemClicked("card_first_sale", "Card First Sale");
                  }}
                />
                <Item
                  value={CurrencyFormating(totals.newEditionSoldPrice || 0)}
                  title={`Card First Sale ($)`}
                  desc="Cards first sale ( from minter to buyer directly ) total USD."
                  className="mt-2 reporting"
                  onClick={() => {
                    onItemClicked("card_first_sale", "Card First Sale ($)");
                  }}
                />

                <Item
                  value={CurrencyFormating(totals.newEditionUserProfPrice || 0)}
                  title={`Card First Sale Users Share ($)`}
                  desc="Cards first sale ( from minter to buyer directly ) total USD users commission."
                  className="mt-2 reporting"
                  onClick={() => {
                    onItemClicked(
                      "card_first_sale",
                      "Card First Sale Users Share ($)"
                    );
                  }}
                />

                <Item
                  value={CurrencyFormating(totals.newEditionHypeuProfPrice || 0)}
                  title={`Card First Sale Hypeu Share ($)`}
                  desc="Cards first sale ( from minter to buyer directly ) total USD HypeU commission."
                  className="mt-2 reporting"
                  onClick={() => {
                    onItemClicked(
                      "card_first_sale",
                      "Card First Sale Hypeu Share ($)"
                    );
                  }}
                />
                <Item
                  value={totals.secEditionSoldCount || 0}
                  title={`Card Secondary Sale`}
                  desc="Cards second sale ( from seler to buyer through 'Make An Offer' ) total count."
                  className="mt-2 reporting"
                  onClick={() => {
                    onItemClicked("card_second_sale", "Card Secondary Sale");
                  }}
                />
                <Item
                  value={CurrencyFormating(totals.secEditionSoldPrice || 0)}
                  title={`Card Secondary Sale ($)`}
                  desc="Cards second sale ( from seler to buyer through 'Make An Offer' ) total USD."
                  className="mt-2 reporting"
                  onClick={() => {
                    onItemClicked(
                      "card_second_sale",
                      "Card Secondary Sale ($)"
                    );
                  }}
                />
                <Item
                  value={CurrencyFormating(
                    totals.secEditionHypeuProfPrice || 0
                  )}
                  title={`Card Secondary Sale Hypeu Share ($)`}
                  desc="Cards second sale ( from seler to buyer through 'Make An Offer' ) total USD HypeU commission."
                  className="mt-2 reporting"
                  onClick={() => {
                    onItemClicked(
                      "card_second_sale",
                      "Card Secondary Sale Hypeu Share ($)"
                    );
                  }}
                />

                <Item
                  value={totals.EditionSoldCount || 0}
                  title={`Total Card Sale`}
                  desc="Cards total sale count."
                  className="mt-2 reporting"
                  onClick={() => {
                    onItemClicked("total_card_sale", "Total Card Sale");
                  }}
                />
                <Item
                  value={CurrencyFormating(totals.EditionSoldPrice || 0)}
                  title={`Total Card Sale ($)`}
                  desc="Cards total sale USD."
                  className="mt-2 reporting"
                  onClick={() => {
                    onItemClicked("total_card_sale", "Total Card Sale ($)");
                  }}
                />
                <Item
                  value={CurrencyFormating(totals.EditionHypeuProfPrice || 0)}
                  title={`Total Card Sale Hypeu Share ($)`}
                  desc="Cards total sale USD HypeU commission."
                  className="mt-2 reporting"
                  onClick={() => {
                    onItemClicked(
                      "total_card_sale",
                      "Total Card Sale Hypeu Share ($)"
                    );
                  }}
                />
              </div>
            </LoadingOverlay>
          </Col>
        </Row>
      </div>
      <div className="dash_partts_inner" ref={sectionRef}>
        {clickedKey !== "" && (
          <>
            <h2 className="title">{clickedTitle}</h2>
            <div className="manage_table table-responsive">
              <DataTable
                className="hypTable1"
                columns={getColumns(clickedKey)}
                data={specificData}
                responsive
                customStyles={customStyles}
                defaultSortField="id"
                defaultSortAsc={false}
                noDataComponent={<EmptyDataLoader />}
                progressPending={loading}
                progressComponent={<CustomLoader />}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
}

const customStyles = {
  rows: {
    style: {
      //minHeight: '72px',
      backgroundColor: "transparent",
      color: "#fff",
      paddingLeft: "10px", // override the row height
    },
  },
  headCells: {
    style: {
      paddingLeft: "10px", // override the cell padding for head cells
      paddingRight: "2px",
      backgroundColor: "transparent",
      fontSize: "20px",
      fontWeight: "600",
      color: "#fff",
    },
  },
  cells: {
    style: {
      paddingLeft: "4px", // override the cell padding for data cells
      paddingRight: "2px",
      paddingTop: "5px",
      paddingBottom: "5px",
      backgroundColor: "transparent",
      fontSize: "18px",
      color: "#fff",
    },
  },
};

const CustomLoader = () => (
  <div
    style={{
      padding: "15px",
      background: "#495A71",
      color: "#fff",
      width: "100%",
      fontSize: "40px",
      textAlign: "center",
      minHeight: "350px",
      lineHeight: "400px",
    }}
  >
    <div>Loading...</div>
  </div>
);
const EmptyDataLoader = () => (
  <div
    style={{
      padding: "15px",
      background: "#495A71",
      color: "#fff",
      width: "100%",
      fontSize: "40px",
      textAlign: "center",
      minHeight: "350px",
      lineHeight: "400px",
    }}
  >
    <div>No data found...</div>
  </div>
);
