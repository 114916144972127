// import moment from "moment-timezone";

let CurrencyFormating = (price) => {
  let USDollar = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  return USDollar.format(Number(price)).replace("$", "$ ");
};

let capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const currencyFormatingInString = (price) => {
  if (isNaN(price)) return "$ 0.00";

  let USDollar = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    useGrouping: false,
  });

  return USDollar.format(Number(price)).replace("$", "$ ");
};

export { CurrencyFormating, capitalizeFirstLetter, currencyFormatingInString };
