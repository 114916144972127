import { useCallback, useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import { Link, useParams } from "react-router-dom";
import { BounceLoader } from "react-spinners";
import { Maneye_img, No_ProfImg } from "../../assets/images";
import { CurrencyFormating } from "../../helpers/currencyHelper";
import axios from "../../axios/axios";
import message from "../../messages";
import moment from "moment";

export default function TopPerFormReportDetails() {
  const { userId } = useParams();
  const [loader, setLoader] = useState(false);
  const [nftData, setNftData] = useState([]);
  const [userDetails, setUserDetails] = useState({});

  const getNftData = useCallback(() => {
    setLoader(true);

    axios.get(`/top-performer-reports/${userId}`).then((res) => {
      setLoader(false);
      if (res.data.success) {
        const { reports, userDetails } = res.data;
        setNftData(reports);
        setUserDetails(userDetails);
      } else {
        message.warning("Oops! Server error");
      }
    });
  }, [userId]);

  useEffect(() => {
    getNftData();
  }, [getNftData]);

  return (
    <div>
      <div className="dash_partts">
        <div className="dash_part_heading">
          <div className="dash_part_heading_left">
            <h2>NFT's Collectables</h2>
          </div>
        </div>

        <div className="reporters_profile">
          <div className="report_profile_image">
            <img
              src={userDetails?.thumbnailUrl || No_ProfImg}
              alt={userDetails?.userName}
              loading="lazy"
              width={154}
              height={154}
            />
          </div>
          <div className="reporter_info">
            <h1 className="reporter_name">{userDetails?.name}</h1>
            <div className="reporter_user_name">@{userDetails?.userName}</div>
            <div className="created_date">
              Created : {moment(userDetails?.createdAt).format("YYYY-MM-DD")}
            </div>
            <div className="info_list">
              <dl className="info_list_item">
                <dt>Total NFTs</dt>
                <dd>{userDetails?.totalNFTs || 0}</dd>
              </dl>
              <dl className="info_list_item">
                <dt>Total NFT Price</dt>
                <dd>{userDetails?.totalNFTPrice || 0}</dd>
              </dl>
            </div>
          </div>
        </div>

        <div className="nft_collect_body">
          <LoadingOverlay
            active={loader}
            spinner={<BounceLoader color="white" />}
          >
            <div className="nft_collect_inner_body">
              {nftData?.nftDetails?.map((item, index) => {
                return (
                  <div key={index} className="nft_collct_inner_body_part">
                    <div className="nft_collect_inner_partts">
                      <div className="nft_collect_top_img">
                        <img
                          src={item?.fileUrl}
                          alt="No Img"
                          loading="lazy"
                          decoding="async"
                        />
                      </div>
                      <div className="nft_collect_bottom_details">
                        <div className="nft_collect_details_left">
                          <h2>{item?.title || userDetails.nickName}</h2>
                          <h5>
                            <small>
                              <i>Total NFTs</i>
                            </small>
                            : {item.totalNFTs || 0}
                          </h5>
                          <h5>
                            <small>
                              <i>Total Price</i>
                            </small>
                            : {CurrencyFormating(item.totalNFTPrice || 0)}
                          </h5>
                          <h5>
                            <small>
                              <i>Current Holder</i>
                            </small>
                            : {item?.nftHolders?.name}
                          </h5>
                          <h5>
                            <small>
                              <i>Total Edition</i>
                            </small>
                            : {item?.editionTotal}
                          </h5>
                        </div>
                        <div className="nft_collect_details_right">
                          <div className="view_but">
                            <Link
                              to={`/nftdetails/${item?._id}/${item?.NFT_identity}`}
                            >
                              <img src={Maneye_img} alt="No Img" />
                              View
                            </Link>
                          </div>
                          <div className="common_img">
                            <img src={item?.nftTypes?.imageUrl} alt="No Img" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </LoadingOverlay>
        </div>
      </div>
    </div>
  );
}
